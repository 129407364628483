import React, {Component} from "react";
import * as PropTypes from "prop-types";
import ToggleButton from "components/ToggleButton";
import {injectIntl} from "react-intl";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ReactDOM from "react-dom";

const portal = document.createElement('div');
portal.classList.add('logo-settings-element-drag-drop');
document.body.appendChild(portal);


class DatabaseLogoSettings extends Component {

    static propTypes = {
        data: PropTypes.object.isRequired,
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.handleDragEnd = this.handleDragEnd.bind(this);
        this.state = {
            data: props.data,
        }
    }

    render() {
        const {data} = this.state;
        let settingsLabel = {};
        Object.keys(data.visible).forEach(key => settingsLabel[key] = this.props.intl.formatMessage({id: `database.logo.settings.visible.${key}`}));
        return (
            <div className="db-heading-settings">
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <Droppable droppableId={`logo-element`} type={'logo-element'}>
                        {(droppableProvided, droppableSnapshot) => (
                            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                                {data.orderElements.map((elementKey, elementIndex) => (
                                    <Draggable key={elementKey} index={elementIndex} draggableId={elementKey}>
                                        {(draggableProvided, draggableSnapshot) => {
                                            const dragItem = <div
                                                ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                                                    <div className="config-item d-flex align-items-center justify-content-between">
                                                        <div>{settingsLabel[elementKey]}</div>
                                                        <div className="d-flex">
                                                            <ToggleButton checked={data.visible[elementKey]}
                                                                        onChange={this.toggleVisible.bind(this, elementKey)}/>
                                                        </div>
                                                    </div>
                                            </div>;
                                            return draggableSnapshot.isDragging ? ReactDOM.createPortal(dragItem, portal) : dragItem;
                                        }}
                                    </Draggable>
                                ))}
                                {droppableProvided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }

    handleDragEnd(result) {
        if (!result.destination || !result.source || result.destination.index === result.source.index) {
            return false;
        }
        let data = {...this.state.data}
        let orderElements = [...data.orderElements];
        const [moved] = orderElements.splice(result.source.index, 1);
        orderElements.splice(result.destination.index, 0, moved);
        data.orderElements = orderElements;
        this.setState({
            data
        });
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }

    toggleVisible(key, enabled) {
        let data = {...this.state.data};
        data.visible[key] = enabled;
        this.setState({data});
        if (this.props.onChange) {
            this.props.onChange(data);
        }
    }
}

export default injectIntl(DatabaseLogoSettings)