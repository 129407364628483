import React, {Component} from "react";
import * as PropTypes from "prop-types";
import CircularProgress from "components/CircularProgress";

export default class ToggleButton extends Component{

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    static propTypes = {
        className: PropTypes.string,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func,
        loading: PropTypes.bool
    };

    render() {
        const {checked, loading} = this.props;
        const className = this.props.className || '';
        if (loading) {
            return (
                <CircularProgress/>
            );
        }
        return (
            <i className={`toggle-icon fad fa-toggle-${checked ? 'on on' : 'off'} ${className}`}
               onClick={this.onClick}/>
        );
    }

    onClick() {
        if(this.props.onChange) {
            this.props.onChange(!this.props.checked);
        }
    }
}