import React, {Component} from 'react';
import moment from "moment";
import IntlMessages from "appUtil/IntlMessages";

export default class Footer extends Component {
    render() {
        return (<footer className="app-footer">
            <div className="d-flex flex-row justify-content-center">
                <div>
                    <span><IntlMessages id="footer.title"/> {moment().format("DD.MM.YYYY")}</span>
                </div>
            </div>
        </footer>);
    }
}
