import React from 'react';
import asyncComponent from '../../../util/asyncComponent';
import RouterBase from "app/routes/RouterBase";

export default class DataPages extends RouterBase {

    get className() {
        return 'app-wrapper';
    }

    get routes() {
        return [
            {
                path: '/:id/detail',
                asyncComponent: () => import('./detail'),
                requiredRoles: ['data-read']
            },
            {
                path: '/:code/:version/view/:catalogId/:viewVersion',
                asyncComponent: () => import('./view'),
                public: true
            },
            {
                path: '/:code/:version/:uuid/read-value/:field(.*)',
                component: asyncComponent(() => import('./read-value')),
                asyncComponent: () => import('./read-value'),
                public: true
            },
        ]
    }
}