import {Component} from "react";
import {Modal} from "reactstrap";
import React from "react";
import * as PropTypes from "prop-types";
import cn from 'classnames';

export default class DrawerModal extends Component {

    constructor(props) {
        super(props);
        this.resizerClick = this.resizerClick.bind(this);
        this.resizerClickOut = this.resizerClickOut.bind(this);
        this.resizerMove = this.resizerMove.bind(this);
        this.state = {
            pointerX: null,
            width: null
        };
        this.divRef = null;
        this.dir = document.documentElement.getAttribute('dir');
    }

    static propTypes = {
        isOpen: PropTypes.bool,
        toggle: PropTypes.func,
        sizable: PropTypes.bool,
        width: PropTypes.string,
        align: PropTypes.string,
        className: PropTypes.string,
        refX: PropTypes.func,
        onClosed: PropTypes.func,
        noOverlay: PropTypes.bool
    };

    componentDidMount() {
        document.addEventListener('mouseup', this.resizerClickOut);
        document.addEventListener('mousemove', this.resizerMove);
        document.addEventListener('touchmove', this.resizerMove);
        if(this.props.refX) {
            this.props.refX(this);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.resizerClick);
        document.removeEventListener('mousemove', this.resizerMove);
        document.removeEventListener('touchmove', this.resizerMove);
        if(this.props.refX) {
            this.props.refX(null);
        }
    }

    render() {
        const align = this.props.align || 'right';
        return (
            <Modal modalClassName={cn({'no-overlay': this.props.noOverlay})} isOpen={this.props.isOpen}
                   toggle={this.props.toggle} onClosed={this.props.onClosed}
                   className={cn(`modal-align-${align}`, {'modal-sizable': this.props.sizable}, this.props.className)}>
                {this.props.sizable ? <div>
                    <div ref={d => this.divRef = d}
                         style={{width: this.state.width ? `${this.state.width}px` : (this.props.width ? this.props.width : undefined)}}
                         children={this.props.children}/>
                    <span className="resizer"
                          onMouseDown={this.resizerClick}
                          onTouchStart={this.resizerClick}
                          onTouchEnd={this.resizerClickOut}>
                        <i className="zmdi zmdi-pause"/>
                    </span>
                </div> : this.props.children}
            </Modal>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.dir = document.documentElement.getAttribute('dir');
    }

    resizerClick(event) {
        event.preventDefault();
        document.body.style.cursor = 'col-resize';
        this.setState({pointerX: event.clientX});
    }

    resizerClickOut(event) {
        if (this.state.pointerX === null) {
            return;
        }
        event.preventDefault();
        this.setState({pointerX: null});
        document.body.style.cursor = null;
    }

    resizerMove(event) {
        if (this.state.pointerX === null) {
            return;
        }
        let align = this.props.align || 'right';
        if (this.dir === 'rtl') {
            align = align === 'left' ? 'right' : 'left';
        }
        const diffX = align === 'left' ? event.clientX - this.state.pointerX : this.state.pointerX - event.clientX;
        let width = this.divRef.clientWidth + diffX;
        if (width > 0) {
            this.setState({pointerX: event.clientX, width});
        }
    }

    getWidth() {
        return this.divRef ? this.divRef.clientWidth : null;
    }

    setWidth(value) {
        this.setState({width: value});
    }
}