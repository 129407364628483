import React from 'react';
import {MINI_DRAWER} from "./../../constants/ActionTypes";
import InstanceLogo from "components/InstanceLogo";

const SidenavLogo = ({drawerType, onClickClose}) => {

    const showMini = drawerType.includes(MINI_DRAWER);

    return (
        <div className="sidebar-header d-flex align-items-center">
            <div className={showMini ? 'mini-logo w-100 d-flex align-items-center' : 'w-100 d-flex align-items-center'}>
                <InstanceLogo editable={true} />
                {onClickClose && <div><i onClick={onClickClose} className="far fa-times pointer"/></div>}
            </div>
        </div>
    );
};


export default SidenavLogo;
